export const COLORS = {
    // General
    'flp-primary': '#62C0C3',
    'flp-primary-highlight': '#83F8FB',
    'flp-white': '#FFFFFF',
    'flp-black': '#000000',
    'flp-primary-black': '#07151D',
    'flp-primary-grey': '#121f25',
    'flp-light-grey': '#5a5c60',
    'flp-red': '#FF0000',
    'flp-red-highlight': '#ff5353',
    'flp-yellow': '#FEC32D',
    'flp-orange': '#FF7629',

    // Text
    'flp-text': '#FFFFFF90',
    'flp-text-highlight': '#FFFFFF',
    'flp-grey': '#B3B3B3',
    'flp-grey-2': '#CCCCCC',
    'flp-text-muted': '#5B646A',
    'flp-tab-selected': '#CCCCCC',
    'flp-info': '#7F999D',
    'flp-info-highlight': '#afc4c7',

    // Buttons & badges
    'flp-button-text': '#62C0C3',
    'flp-button-bg': '#346A7010',
    'flp-button-bg-highlight': '#346A7050',
    'flp-button-border': '#346A7050',
    'flp-button-free-text': '#78a9f2',
    'flp-button-free-text-ighlight': '#a7caff',
    'flp-button-free-bg': '#1e2c3e',
    'flp-button-free-bg-highlight': '#1e2c3e',
    'flp-button-premium-text': '#7ABEC2',
    'flp-button-premium-text-highlight': '#8ddce1',
    'flp-button-premium-bg': '#1c2c32',
    'flp-button-premium-bg-highlight': '#7ABEC235',
    'flp-button-plus-bg': '#25363c',
    'flp-button-plus-bg-highlight': '#30464e',
    'flp-button-plus-text': '#7ABEC2',
    'flp-button-plus-text-highlight': '#8ddce1',
    'flp-button-pro-text': '#7676e8',
    'flp-button-pro-text-highlight': '#8f8fff',
    'flp-button-pro-bg': '#222947',
    'flp-button-pro-bg-highlight': '#313c69',
    'flp-button-yellow-background': '#FEC32D25',
    'flp-button-yellow-background-highlight': '#FEC32D60',
    'flp-button-yellow-text': '#FEC32D',
    'flp-button-yellow-text-highlight': '#fdd879',
    'flp-button-red-background': '#FF000025',
    'flp-button-red-background-highlight': '#FF000060',
    'flp-button-red-text': '#FF0000',
    'flp-button-red-text-highlight': '#ff5353',
    'flp-button-grey-background': '#1e262c',
    'flp-button-grey-background-highlight': '#2e3841',
    'flp-button-grey-text': '#81888D',
    'flp-button-grey-text-highlight': '#8c9398',
    'flp-button-orange-background': '#FF7629',
    'flp-button-orange-background-highlight': '#FF9151',

    // Other elements
    'flp-search-bg': '#000000',
    'flp-dropdown-bg': '#04121a',
    'flp-row-divider': '#5B646A10',
    'flp-progress-bar': '#346A70',
    'flp-progress-bar-background': '#346A7025',
    'flp-progress-bar-yellow': '#FEC32D',
    'flp-progress-bar-yellow-background': '#FEC32D25',
    'flp-toggle-off-border': '#5B646A25',
    'flp-toggle-off-bg': '#5B646A10',
    'flp-toggle-off-disc': '#5B646A',
    'flp-toggle-on-border': '#62C0C325',
    'flp-toggle-on-bg': '#62C0C310',
    'flp-toggle-on-disc': '#62C0C3',
    'flp-new-text': '#12EB01',
    'flp-new-background': '#203E11',
}
