import { useCallback, useContext, useMemo } from 'react'
import { observer } from 'mobx-react'

import { COLORS } from '@styles/Colors'
import { AppStoreContext } from '@store/RootStore'
import { EllipseIcon } from '@components/icons/EllipseIcon'
import { InstallFilter, InstallFilters } from '@constants/filters'

type IFiltersProps = {
    isFiltering: boolean
    onChangeFilter: () => void
}

export const Filters = ({ isFiltering = false, onChangeFilter }: IFiltersProps) => {
    const appStore = useContext(AppStoreContext)
    const { feed: feedStore, scale } = appStore
    const {
        selectedInstallStatus,
        setActiveInstallStatus,
        resetSelectedTagIfDoesNotExist,
        pluginsInstalledCount,
        pluginsAvailableCount,
        pluginsUpdatableCount,
        activeQueueCount,
        pluginsList,
        filterByNewBadge,
    } = feedStore
    const onClickFilter = useCallback(
        (filter: InstallFilter | null) => {
            return () => {
                setActiveInstallStatus(filter)
                resetSelectedTagIfDoesNotExist()
                onChangeFilter()
            }
        },
        [onChangeFilter],
    )
    const showNewBadge = pluginsList.some(({ isNew }) => {
        return isNew
    })

    return (
        <div className="flex flex-col gap-11px pt-4 mb-20px">
            <div className={`flp-side-menu pt-4px ${!selectedInstallStatus ? 'active' : ''}`} onClick={onClickFilter(null)}>
                <div className="w-12px">
                    {!selectedInstallStatus && isFiltering && (
                        <EllipseIcon
                            className="flp-icon"
                            style={{
                                '--icon-fill-color': COLORS['flp-white'],
                                '--icon-fill-hover-color': COLORS['flp-white'] as string,
                                width: `${8 * scale}px`,
                                height: `${8 * scale}px`,
                            }}
                        />
                    )}
                </div>
                <div className="flex justify-between items-center grow">
                    <p className="whitespace-nowrap grow">All</p>
                    {showNewBadge && (
                        <div className="flp-new" onClick={filterByNewBadge}>
                            NEW
                        </div>
                    )}
                </div>
            </div>
            {InstallFilters.map((filter: InstallFilter) => {
                const count = useMemo(() => {
                    switch (filter) {
                        case InstallFilter.INSTALLED:
                            return pluginsInstalledCount
                        case InstallFilter.AVAILABLE:
                            return pluginsAvailableCount
                        case InstallFilter.UPDATES:
                            return pluginsUpdatableCount
                        case InstallFilter.ACTIVE_QUEUE:
                            return activeQueueCount
                        default:
                            return 0
                    }
                }, [filter, pluginsInstalledCount, pluginsAvailableCount, pluginsUpdatableCount, activeQueueCount])
                const countClassName = useMemo(() => {
                    switch (filter) {
                        case InstallFilter.UPDATES:
                        case InstallFilter.ACTIVE_QUEUE:
                            return 'flp-count-ok'
                        case InstallFilter.INSTALLED:
                        case InstallFilter.AVAILABLE:
                        default:
                            return ''
                    }
                }, [filter])
                const isSelected = useMemo(() => {
                    return selectedInstallStatus === filter && isFiltering
                }, [isFiltering, filter, selectedInstallStatus])
                const showCountBadge = useMemo(() => {
                    return [InstallFilter.UPDATES, InstallFilter.ACTIVE_QUEUE].includes(filter) && count > 0
                }, [filter, count])

                return (
                    <div className={`flp-side-menu ${isSelected ? 'active' : ''}`} key={filter} onClick={onClickFilter(filter)}>
                        <div className="w-12px">
                            {isSelected && (
                                <EllipseIcon
                                    className="flp-icon"
                                    style={{
                                        '--icon-fill-color': COLORS['flp-white'],
                                        '--icon-fill-hover-color': COLORS['flp-white'] as string,
                                        width: `${8 * scale}px`,
                                        height: `${8 * scale}px`,
                                    }}
                                />
                            )}
                        </div>
                        <div className="flex justify-between items-center grow">
                            <p className="whitespace-nowrap grow">{filter}</p>
                            {showCountBadge && <div className={`flp-count ${countClassName}`}>{count}</div>}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default observer(Filters)
